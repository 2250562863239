import { ApolloError } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  CollectionOwnershipLevel,
  CollectionRole,
  IntrinsicGroupId,
  stableEmptyArray,
} from "@hex/common";
import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";

import { ORG_ID } from "../../../../orgs.js";
import { useToaster } from "../../../common/Toasts.js";
import {
  CollectionWithHexes2Fragment,
  GetCollectionForShowTab2Document,
} from "../collection-show/CollectionShowTab2.generated.js";
import { GetCollectionsForHome2Document } from "../collections-list/CollectionsListTab2.generated.js";
import { GetOrgForCollectionMembershipDocument } from "../dialogs/CollectionMembership.generated.js";
import { MemberGroup, MemberUser } from "../dialogs/CollectionMembership.js";
import { SaveCollectionPermissionsArgs } from "../dialogs/CollectionPermissions.js";
import { useUpdateCollectionMutation } from "../dialogs/EditCollectionDialog.generated.js";

export const useUpdateCollectionGrantsData = (
  selectedUsers: MemberUser[],
  selectedGroups: MemberGroup[],
  orgCollectionRole: CollectionRole | undefined,
): SaveCollectionPermissionsArgs => {
  const { managerUserIds, memberUserIds } = useMemo(() => {
    const groupedUsers = groupBy(selectedUsers, "role");
    return {
      memberUserIds:
        groupedUsers[CollectionRole.MEMBER]?.map((u) => u.id) ??
        stableEmptyArray(),
      managerUserIds:
        groupedUsers[CollectionRole.MANAGER]?.map((u) => u.id) ??
        stableEmptyArray(),
    };
  }, [selectedUsers]);

  const { managerGroupIds, memberGroupIds } = useMemo(() => {
    const groupedGroups = groupBy(selectedGroups, "role");
    return {
      memberGroupIds:
        groupedGroups[CollectionRole.MEMBER]?.map((g) => g.id) ??
        stableEmptyArray(),
      managerGroupIds:
        groupedGroups[CollectionRole.MANAGER]?.map((g) => g.id) ??
        stableEmptyArray(),
    };
  }, [selectedGroups]);

  const { managerIntrinsicGroupIds, memberIntrinsicGroupIds } = useMemo(
    () => ({
      memberIntrinsicGroupIds:
        orgCollectionRole === CollectionRole.MEMBER
          ? [IntrinsicGroupId.MEMBERS]
          : (stableEmptyArray<IntrinsicGroupId>() as IntrinsicGroupId[]),
      managerIntrinsicGroupIds:
        orgCollectionRole === CollectionRole.MANAGER
          ? [IntrinsicGroupId.MEMBERS]
          : (stableEmptyArray<IntrinsicGroupId>() as IntrinsicGroupId[]),
    }),
    [orgCollectionRole],
  );

  return useMemo(
    () => ({
      managerUserIds,
      memberUserIds,
      managerGroupIds,
      memberGroupIds,
      managerIntrinsicGroupIds,
      memberIntrinsicGroupIds,
    }),
    [
      managerUserIds,
      memberUserIds,
      managerGroupIds,
      memberGroupIds,
      managerIntrinsicGroupIds,
      memberIntrinsicGroupIds,
    ],
  );
};

export const useUpdateCollectionGrants = (
  collection: CollectionWithHexes2Fragment,
  onError?: (error: ApolloError) => void,
): ((args: SaveCollectionPermissionsArgs) => Promise<void>) => {
  const toaster = useToaster();
  const [updateCollection] = useUpdateCollectionMutation();

  return useCallback(
    async ({
      managerGroupIds,
      managerIntrinsicGroupIds,
      managerUserIds,
      memberGroupIds,
      memberIntrinsicGroupIds,
      memberUserIds,
    }) => {
      try {
        await updateCollection({
          variables: {
            collectionId: collection.id,
            name: collection.name,
            description: collection.description,
            emoji: collection.emoji,
            managerGroupIds,
            managerIntrinsicGroupIds,
            managerUserIds,
            memberGroupIds,
            memberIntrinsicGroupIds,
            memberUserIds,
          },
          refetchQueries: [
            {
              query: GetCollectionForShowTab2Document,
              variables: { collectionId: collection.id },
            },
            {
              query: GetCollectionsForHome2Document,
              variables: { ownershipLevel: CollectionOwnershipLevel.ALL },
            },
            {
              query: GetOrgForCollectionMembershipDocument,
              variables: { orgId: ORG_ID },
            },
          ],
        });
      } catch (err) {
        console.error(err);
        onError?.(err as ApolloError);
        toaster.show({
          message:
            "Failed to update collection sharing settings. Please try again.",
          intent: Intent.DANGER,
        });
      }
    },
    [
      collection.description,
      collection.emoji,
      collection.id,
      collection.name,
      toaster,
      updateCollection,
      onError,
    ],
  );
};
