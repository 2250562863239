import { gql, useApolloClient } from "@apollo/client";
import { CollectionId } from "@hex/common";
import { useCallback } from "react";

import {
  GetStarredCollectionsForSidebarDocument,
  GetStarredCollectionsForSidebarQuery,
  GetStarredCollectionsForSidebarQueryVariables,
  StarredCollectionForSidebarFragment,
  StarredCollectionForSidebarFragmentDoc,
} from "../../sidebar/FavoriteCollections.generated.js";

import {
  StarCollectionMutation,
  StarCollectionMutationVariables,
  useStarCollectionMutation,
} from "./useStarCollection.generated.js";

gql`
  mutation starCollection($collectionId: CollectionId!, $starred: Boolean!) {
    starCollection(collectionId: $collectionId, starred: $starred) {
      id
      starredByViewer
    }
  }
`;

export const useStarCollection = (
  collectionId: CollectionId,
  handleError: (
    starred: StarCollectionMutation["starCollection"]["starredByViewer"],
  ) => void,
  handleUpdate?: (
    collectionId: CollectionId,
    starred: StarCollectionMutation["starCollection"]["starredByViewer"],
  ) => void,
): ((
  starred: StarCollectionMutation["starCollection"]["starredByViewer"],
) => void) => {
  const [starCollection] = useStarCollectionMutation({
    refetchQueries: [GetStarredCollectionsForSidebarDocument],
  });
  const client = useApolloClient();

  const handleStarChange = useCallback(
    (starred) => {
      const variables: StarCollectionMutationVariables = {
        collectionId,
        starred,
      };

      starCollection({
        variables,
        optimisticResponse: {
          __typename: "Mutation",
          starCollection: {
            id: collectionId,
            __typename: "Collection",
            starredByViewer: variables.starred,
          },
        },
        update: (_, { data }) => {
          if (data != null) {
            handleUpdate?.(collectionId, data.starCollection.starredByViewer);
          }

          // Update the cache for the starred collections query in the sidebar
          const collectionFragment =
            client.readFragment<StarredCollectionForSidebarFragment>({
              fragment: StarredCollectionForSidebarFragmentDoc,
              fragmentName: "StarredCollectionForSidebar",
              id: client.cache.identify({
                __typename: "Collection",
                id: collectionId,
              }),
            });
          const queryResult = client.readQuery<
            GetStarredCollectionsForSidebarQuery,
            GetStarredCollectionsForSidebarQueryVariables
          >({
            query: GetStarredCollectionsForSidebarDocument,
          });
          if (queryResult == null || collectionFragment == null) {
            return;
          }
          const updatedCollection = {
            ...collectionFragment,
            starredByViewer: starred,
          };
          let newCollections = [...queryResult.collections.edges];
          if (starred) {
            newCollections.push({
              __typename: "CollectionsEdge",
              node: {
                ...updatedCollection,
              },
            });
          } else {
            newCollections = newCollections.filter(
              (h) => h.node.id !== collectionId,
            );
          }
          client.writeQuery<
            GetStarredCollectionsForSidebarQuery,
            GetStarredCollectionsForSidebarQueryVariables
          >({
            query: GetStarredCollectionsForSidebarDocument,
            data: {
              ...queryResult,
              __typename: "Query",
              collections: {
                ...queryResult.collections,
                edges: newCollections,
              },
            },
          });
        },
      }).catch((e) => {
        console.error(e);
        handleError(!starred);
      });
    },
    [collectionId, starCollection, client, handleUpdate, handleError],
  );

  return handleStarChange;
};
