import { Classes, Intent } from "@blueprintjs/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

import {
  BorderStyles,
  DESCRIPTION_MAX_LENGTH,
  DescriptionWrapper,
} from "../../../app/ProjectDescription";
import { CharacterCountIndicator } from "../../../common/CharacterCountIndicator";
import { ControlledContentEditable } from "../../../common/ControlledContentEditable";
import { useToaster } from "../../../common/Toasts";

import { UpdateCollectionMetadataProps } from "./CollectionName";

const SimpleDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export interface CollectionDescriptionProps {
  description: string | null;
  updateCollectionProps?: UpdateCollectionMetadataProps;
}

export const DescriptionContentEditable = styled(ControlledContentEditable)<{
  $small?: boolean;
  $editable: boolean;
  $placeholder?: boolean;
}>`
  display: block;
  width: 100%;
  max-width: 700px;

  overflow: hidden;

  color: ${({ $placeholder, theme }) =>
    $placeholder ? theme.fontColor.PLACEHOLDER : theme.fontColor.DEFAULT};

  font-size: ${({ $small, theme }) =>
    !$small ? theme.fontSize.DEFAULT : theme.fontSize.SMALL};

  line-height: 20px;
  white-space: normal;

  background: transparent;

  border: none;
  outline: none;

  resize: none;
  ${({ $editable }) =>
    $editable
      ? BorderStyles
      : css`
          padding: 3px;
        `}
`;

export const CollectionDescription: React.ComponentType<CollectionDescriptionProps> =
  React.memo(function CollectionDescription({
    description,
    updateCollectionProps,
  }: CollectionDescriptionProps) {
    const toaster = useToaster();
    const canEdit = updateCollectionProps?.canEdit;

    const [currentDescription, _setCurrentDescription] = useState<string>(
      description != null ? description : "",
    );
    const descriptionRef = useRef<ControlledContentEditable>(null);
    const [editingDescription, setIsEditingDescription] =
      useState<boolean>(false);

    const setCurrentDescription = useCallback(
      (newDescription: string | null): void => {
        _setCurrentDescription(newDescription != null ? newDescription : "");
      },
      [_setCurrentDescription],
    );

    const showPlaceholder = useMemo(
      () => !editingDescription && currentDescription.length === 0,
      [editingDescription, currentDescription.length],
    );

    // if the description changes from the "settings dialog", make sure to update here.
    useEffect(() => {
      setCurrentDescription(description);
    }, [description, setCurrentDescription]);

    const save = useCallback(async (): Promise<void> => {
      setCurrentDescription(currentDescription);
      setIsEditingDescription(false);

      if (currentDescription.length > DESCRIPTION_MAX_LENGTH) {
        toaster.show({
          message: "Description exceeds maximum character limit.",
          intent: Intent.DANGER,
        });
        return;
      }
      await updateCollectionProps?.onSave({
        newDescription: currentDescription !== "" ? currentDescription : null,
      });
    }, [
      currentDescription,
      setCurrentDescription,
      toaster,
      updateCollectionProps,
    ]);

    return canEdit ? (
      <CollectionDescriptionWrapper>
        <DescriptionContentEditable
          ref={descriptionRef}
          $editable={true}
          $placeholder={showPlaceholder}
          content={showPlaceholder ? "Add description..." : currentDescription}
          isEditing={editingDescription}
          maxLength={DESCRIPTION_MAX_LENGTH}
          onBlur={save}
          onCancel={save}
          onChange={setCurrentDescription}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => setIsEditingDescription(true)}
          onSave={save}
        />
        <DescriptionLengthLimitWrapper>
          <DescriptionLengthLimit
            $hidden={!editingDescription}
            count={currentDescription.length}
            max={DESCRIPTION_MAX_LENGTH}
            small={true}
          />
        </DescriptionLengthLimitWrapper>
      </CollectionDescriptionWrapper>
    ) : description ? (
      <SimpleDescription>{description}</SimpleDescription>
    ) : null;
  });

const CollectionDescriptionWrapper = styled(DescriptionWrapper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
`;

const DescriptionLengthLimitWrapper = styled.div`
  padding-top: 3px;
  padding-right: 3px;
  height: 20px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

const DescriptionLengthLimit = styled(CharacterCountIndicator)<{
  $hidden: boolean;
}>`
  &&.${Classes.TAG} {
    min-height: 0;
  }
  margin-left: auto;
  padding-top: 3px;
  flex: ${({ $hidden }) => ($hidden ? 0 : 1)};
  height: auto;
  overflow: hidden;
`;
