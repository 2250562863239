import { Classes } from "@blueprintjs/core";
import {
  CollectionRole,
  guardNever,
  humanReadableCollectionRole,
  notEmpty,
} from "@hex/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import { HexButton, HexDivider, HexTooltip } from "../../../../hex-components";
import { useCurrentUser } from "../../../../hooks/me/useCurrentUser";
import { CollectionGrantFragment } from "../../../../mutations/collections.generated";
import { Heading } from "../../../Heading";
import { LockIcon, UserIcon, WorkspaceIcon } from "../../../icons/CustomIcons";
import {
  PermissionRowData,
  PermissionsRowUserData,
} from "../../../share/PermissionRow";

const PermissionSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 2px;
`;

const PermissionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const Role = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const NameOrEmail = styled.div`
  word-break: break-word;
`;

const StyledHexButton = styled(HexButton)`
  &&.${Classes.SMALL}.${Classes.BUTTON} {
    min-height: initial;
    padding: 0px;

    transition: all ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};

    &&.${Classes.BUTTON}:not(.${Classes.DISABLED}):hover {
      color: ${({ theme }) => theme.fontColor.DEFAULT};

      background-color: transparent;
    }

    && .${Classes.ICON} {
      margin-left: 0px;
    }
  }
`;

const MinimalLink = styled.a`
  display: flex;
  gap: 4px;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;

  &:hover {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
  }
`;

const StyledHexTooltip = styled(HexTooltip)`
  && {
    display: flex;
  }
`;

export interface CollectionSharingSummaryProps {
  collectionGrants?: readonly CollectionGrantFragment[];
  minimal?: boolean;
  noTooltip?: boolean;
  onClick?: () => void;
  disableTooltip?: boolean;
}

export const CollectionSharingSummary: React.ComponentType<CollectionSharingSummaryProps> =
  React.memo(function CollectionSharingSummary({
    collectionGrants = [],
    disableTooltip,
    minimal = false,
    noTooltip = false,
    onClick,
  }: CollectionSharingSummaryProps) {
    const currentUser = useCurrentUser();

    const { groupGrants, sharedWithOrg, userGrants } = useMemo(() => {
      const uGrants: PermissionsRowUserData[] = [];
      const gGrants: PermissionRowData[] = [];
      let hasOrgShare = false;

      collectionGrants.forEach((grant) => {
        switch (grant.holder.__typename) {
          case "User":
            if (grant.holder.userId !== currentUser?.id) {
              uGrants.push({
                dataType: "user",
                id: grant.holder.userId,
                role: grant.role,
                ...grant.holder,
                imageUrl: grant.holder.imageUrl ?? undefined,
                name: grant.holder.name,
                email: grant.holder.email,
              });
            }
            break;
          case "Group":
            gGrants.push({
              dataType: "group",
              id: grant.holder.groupId,
              name: grant.holder.groupName,
              role: grant.role,
              ...grant.holder,
            });
            break;
          case "IntrinsicGroup":
            if (grant.holder.intrinsicGroupId === "MEMBERS") {
              hasOrgShare = true;
            }
            break;
          default:
            guardNever(
              grant.holder,
              (grant.holder as { __typename: string }).__typename,
            );
        }
      });

      return {
        sharedWithOrg: hasOrgShare,
        userGrants: uGrants,
        groupGrants: gGrants,
      };
    }, [collectionGrants, currentUser?.id]);

    let summary = (
      <PermissionSummary>
        {sharedWithOrg && (
          <>
            <PermissionRow>Shared with your workspace</PermissionRow>
            {(userGrants.length > 0 || groupGrants.length > 0) && (
              <HexDivider />
            )}
          </>
        )}
        {userGrants.length > 0 && (
          <Heading renderAs="h1" styleAs="h5">
            Users
          </Heading>
        )}
        {userGrants.map((grant, index) => (
          <PermissionRow key={`user-${index}`}>
            <NameOrEmail>{grant.name || grant.email}</NameOrEmail>
            <Role>
              ({humanReadableCollectionRole(grant.role as CollectionRole)})
            </Role>
          </PermissionRow>
        ))}
        {userGrants.length > 0 && groupGrants.length > 0 && <HexDivider />}
        {groupGrants.length > 0 && (
          <Heading renderAs="h1" styleAs="h5">
            Groups
          </Heading>
        )}
        {groupGrants.map((grant, index) => (
          <PermissionRow key={`group-${index}`}>
            <NameOrEmail>{grant.name} </NameOrEmail>
            <Role>
              ({humanReadableCollectionRole(grant.role as CollectionRole)})
            </Role>
          </PermissionRow>
        ))}
      </PermissionSummary>
    );

    let icon = <UserIcon />;
    let orgSummary;
    let userSummary;
    let groupSummary;
    let unshared = false;
    if (sharedWithOrg) {
      icon = <WorkspaceIcon />;
      orgSummary = `workspace`;
    }

    if (userGrants.length > 0) {
      userSummary = `${userGrants.length} user${
        userGrants.length !== 1 ? "s" : ""
      }`;
    }
    if (groupGrants.length > 0) {
      groupSummary = `${groupGrants.length} group${
        groupGrants.length !== 1 ? "s" : ""
      }`;
    }

    if (!sharedWithOrg && userGrants.length === 0 && groupGrants.length === 0) {
      unshared = true;
      icon = <LockIcon />;
      summary = <>This collection has not been shared</>;
    }

    const sharingSummary = `Shared with ${[
      orgSummary,
      !sharedWithOrg ? userSummary : undefined,
      !sharedWithOrg ? groupSummary : undefined,
    ]
      .filter(notEmpty)
      .join(", ")}`;

    return (
      <>
        {noTooltip ? (
          summary
        ) : (
          <StyledHexTooltip
            content={summary}
            disabled={disableTooltip}
            hoverOpenDelay={400}
            minimal={true}
            placement="bottom-start"
          >
            {minimal ? (
              <MinimalLink>{unshared ? "Private" : sharingSummary}</MinimalLink>
            ) : (
              <StyledHexButton
                icon={icon}
                minimal={true}
                small={true}
                text={unshared ? "Private" : sharingSummary}
                onClick={onClick}
              />
            )}
          </StyledHexTooltip>
        )}
      </>
    );
  });
